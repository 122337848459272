import { useState } from 'react';
import Icon, { IconType } from '@/components/common/icons';
import cls from 'classnames';
import { AppList } from '@/layouts/main/Footer';
import { FormattedMessage } from 'react-intl';
import styles from './SocialApp.module.scss';
import { STATIC_ASSETS_URL } from '@/constants/common';

const SocialIcon: React.FC<AppList> = ({ name, url, index }) => {
  const [active, setActive] = useState<boolean>(false);
  const [wechatActive, setWechatActive] = useState<boolean>(false);

  const handleMouseOver = () => {
    setActive(true);
    if (index === 0) setWechatActive(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
    if (index === 0) setWechatActive(false);
  };

  const handleIconClick = (url?: string) => {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  };

  return (
    <span
      className={cls(styles['app-list'])}
      onClick={handleIconClick.bind(null, url)}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {wechatActive && (
        <span className={cls(styles['footer-wechat-code'])}>
          <img
            src={`${STATIC_ASSETS_URL}/overseacdn/homepage/static/images/wechatCode.png`}
            alt="logo"
            width={96}
            height={96}
          />
          <FormattedMessage
            id="footer.wechat-code1"
            defaultMessage="Scan the QR code"
          />
          <FormattedMessage
            id="footer.wechat-code2"
            defaultMessage="follow the WeChat public account"
          />
        </span>
      )}
      <span
        className={cls(styles['icon'])}
        style={{ display: active ? 'none' : '' }}
      >
        <Icon type={name as IconType} />
      </span>
      <span
        className={cls(styles['icon'])}
        style={{ display: active ? '' : 'none' }}
      >
        <Icon type={`${name}Active` as IconType} />
      </span>
    </span>
  );
};

export default SocialIcon;
