import { COOKIES_NOTIFY_KEY } from '@/constants/common';
import create from 'zustand';

interface State {
  visible: boolean;
}

const initialState: State = {
  visible:
    typeof window !== 'undefined'
      ? !localStorage.getItem(COOKIES_NOTIFY_KEY)
      : false,
};

const store = create<State>(() => ({ ...initialState }));

export const setVisible = (visible: boolean) => {
  if (!visible) {
    localStorage.setItem(
      COOKIES_NOTIFY_KEY,
      Date.now().toString() /** anything */
    );
  }
  store.setState({ visible });
};

export default store;
