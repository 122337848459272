import type { SVGIconProps } from '.';

export const BG1: React.FC<SVGIconProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 744 845"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.08">
        <g filter="url(#filter0_d_509_4293)">
          <path
            d="M414.535 348.385C409.251 333.591 395.238 323.715 379.529 323.715H285.444C272.793 323.715 263.961 336.248 268.216 348.163L297.451 430.02C302.734 444.814 316.747 454.689 332.457 454.689H426.541C439.192 454.689 448.025 442.156 443.77 430.242L414.535 348.385Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter1_d_509_4293)">
          <path
            d="M461.727 281.135C456.444 266.341 442.431 256.465 426.721 256.465H332.637C319.986 256.465 311.153 268.998 315.408 280.913L344.643 362.77C349.927 377.564 363.94 387.439 379.649 387.439H473.734C486.385 387.439 495.217 374.906 490.962 362.992L461.727 281.135Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_509_4293)">
          <path
            d="M515.351 210.025C510.068 195.231 496.055 185.356 480.345 185.356H386.261C373.61 185.356 364.777 197.889 369.032 209.803L398.267 291.661C403.551 306.455 417.564 316.33 433.273 316.33H527.358C540.009 316.33 548.841 303.797 544.586 291.883L515.351 210.025Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter3_d_509_4293)">
          <path
            d="M571.306 134.252C566.023 119.458 552.01 109.583 536.301 109.583H442.216C429.565 109.583 420.732 122.116 424.987 134.03L454.222 215.888C459.506 230.682 473.519 240.557 489.228 240.557H583.313C595.964 240.557 604.796 228.024 600.541 216.11L571.306 134.252Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter4_d_509_4293)">
          <path
            d="M362.622 421.533C357.338 406.739 343.325 396.864 327.616 396.864H233.531C220.88 396.864 212.048 409.397 216.303 421.311L245.538 503.169C250.821 517.963 264.834 527.838 280.544 527.838H374.628C387.279 527.838 396.112 515.305 391.857 503.391L362.622 421.533Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter5_d_509_4293)">
          <path
            d="M315.43 492.323C310.147 477.529 296.134 467.653 280.425 467.653H186.34C173.689 467.653 164.856 480.186 169.111 492.1L198.346 573.958C203.63 588.752 217.643 598.627 233.352 598.627H327.437C340.088 598.627 348.92 586.094 344.665 574.18L315.43 492.323Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter6_d_509_4293)">
          <path
            d="M265.877 563.112C260.593 548.318 246.58 538.443 230.871 538.443H136.786C124.135 538.443 115.303 550.976 119.558 562.89L148.792 644.748C154.076 659.542 168.089 669.417 183.798 669.417H277.883C290.534 669.417 299.367 656.884 295.112 644.97L265.877 563.112Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_509_4293"
          x="124.972"
          y="214.589"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_509_4293"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_509_4293"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_509_4293"
          x="172.165"
          y="147.339"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_509_4293"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_509_4293"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_509_4293"
          x="225.789"
          y="76.2294"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_509_4293"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_509_4293"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_509_4293"
          x="281.744"
          y="0.456409"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_509_4293"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_509_4293"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_509_4293"
          x="73.0591"
          y="287.738"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_509_4293"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_509_4293"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_509_4293"
          x="25.8677"
          y="358.527"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_509_4293"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_509_4293"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_509_4293"
          x="-23.686"
          y="429.316"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_509_4293"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_509_4293"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const BG2: React.FC<SVGIconProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 659 698"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.08">
        <g filter="url(#filter0_d_452_7236)">
          <path
            d="M438.535 201.502C433.251 186.708 419.238 176.832 403.529 176.832H309.444C296.793 176.832 287.961 189.366 292.216 201.28L321.451 283.137C326.734 297.931 340.747 307.807 356.457 307.807H450.541C463.192 307.807 472.025 295.274 467.77 283.359L438.535 201.502Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter1_d_452_7236)">
          <path
            d="M485.727 134.252C480.444 119.458 466.431 109.583 450.721 109.583H356.637C343.986 109.583 335.153 122.116 339.408 134.03L368.643 215.888C373.927 230.682 387.94 240.557 403.649 240.557H497.734C510.385 240.557 519.217 228.024 514.962 216.11L485.727 134.252Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_452_7236)">
          <path
            d="M386.622 274.651C381.338 259.857 367.325 249.982 351.616 249.982H257.531C244.88 249.982 236.048 262.515 240.303 274.429L269.538 356.286C274.821 371.08 288.834 380.956 304.544 380.956H398.628C411.279 380.956 420.112 368.423 415.857 356.508L386.622 274.651Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter3_d_452_7236)">
          <path
            d="M339.43 345.44C334.147 330.646 320.134 320.771 304.425 320.771H210.34C197.689 320.771 188.856 333.304 193.111 345.218L222.346 427.076C227.63 441.87 241.643 451.745 257.352 451.745H351.437C364.088 451.745 372.92 439.212 368.665 427.298L339.43 345.44Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter4_d_452_7236)">
          <path
            d="M289.877 416.229C284.593 401.436 270.58 391.56 254.871 391.56H160.786C148.135 391.56 139.303 404.093 143.558 416.007L172.792 497.865C178.076 512.659 192.089 522.534 207.798 522.534H301.883C314.534 522.534 323.367 510.001 319.112 498.087L289.877 416.229Z"
            stroke="#2D2D2D"
            strokeWidth="1.16573"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_452_7236"
          x="148.972"
          y="67.706"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_452_7236"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_452_7236"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_452_7236"
          x="196.165"
          y="0.456348"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_452_7236"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_452_7236"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_452_7236"
          x="97.0591"
          y="140.855"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_452_7236"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_452_7236"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_452_7236"
          x="49.8677"
          y="211.644"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_452_7236"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_452_7236"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_452_7236"
          x="0.31398"
          y="282.434"
          width="462.041"
          height="415.297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33.035" />
          <feGaussianBlur stdDeviation="70.7893" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.337255 0 0 0 0 0.117647 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_452_7236"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_452_7236"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
