import { STATIC_ASSETS_URL } from '@/constants/common';

interface Props {
  home?: boolean;
}

const Logo: React.FC<Props> = ({ home }) => {
  return (
    <img
      src={`${STATIC_ASSETS_URL}/overseacdn/homepage/static/images/logo${
        home ? '-white' : ''
      }.svg`}
      alt="logo"
      width={207}
      height={40}
    />
  );
};
export default Logo;
