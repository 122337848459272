export const gaEventName = {
  PAGE_VIEW: 'page_view', // pv
  UNIQUE_VISITOR: 'unique_visitor', // uv
  LOGIN_CLICK: 'login_click', // 点击登录
  EXPLORE_BUSINESS_CLICK: 'explore_business_click', // 点击探索商机
  CONTACT_US_NEXT_CLICK: 'contact_us_next_click', // 点击联系我们表单下一步
  CONTACT_US_SUBMIT: 'contact_us_submit', // 提交联系我们表单
  SCROLL: 'scroll', // 滚动
} as const;

type ValueOf<T> = T[keyof T];
export type GaEventName = ValueOf<typeof gaEventName>;
