import dayjs from 'dayjs';
import { GaEventName } from 'src/constants/googleAnalytics';

export const gtagHasLoaded = () => {
  return Boolean(document.getElementById('gtag-init'));
};

export function addGtagEvent(
  event: GaEventName,
  options?: Record<string, string>,
  isUV = false,
  uvId?: string
) {
  if (!gtagHasLoaded()) return;
  //发送请求
  const postGtag = (event: GaEventName, options?: Record<string, string>) => {
    if (options) {
      gtag('event', event, options);
      return;
    }
    gtag('event', event);
  };
  if (!uvId) {
    uvId = event;
  }
  //uv处理
  if (isUV) {
    const lastLoginDate = localStorage.getItem(uvId);
    if (
      !lastLoginDate ||
      dayjs(lastLoginDate) < dayjs(dayjs().format('YYYY.MM.DD'))
    ) {
      postGtag(event, options);
    }
    localStorage.setItem(uvId, dayjs().format('YYYY.MM.DD'));
    return;
  }
  postGtag(event, options);
}
