import React from 'react';
import { BG1, BG2 } from './Background';
import { Wechat, WechatActive } from './Wechat';
import { Instagram, InstagramActive } from './Instagram';
import { Youtube, YoutubeActive } from './Youtube';
import { Twitter, TwitterActive } from './Twitter';
import { Linkedin, LinkedinActive } from './Linkedin';

import { LoadingOutlinedIcon } from './Loading';
import SuccessIcon from './Success';

export interface SVGIconProps {
  className?: string;
}

const icons = {
  loadingOutlined: LoadingOutlinedIcon,
  contactUsBg1: BG1,
  success: SuccessIcon,
  contactUsBg2: BG2,
  wechat: Wechat,
  wechatActive: WechatActive,
  instagram: Instagram,
  instagramActive: InstagramActive,
  youtube: Youtube,
  youtubeActive: YoutubeActive,
  twitter: Twitter,
  twitterActive: TwitterActive,
  linkedin: Linkedin,
  linkedinActive: LinkedinActive,
};

export type IconType = keyof typeof icons;

interface IconProps extends SVGIconProps {
  type: IconType;
}

const Icon: React.FC<IconProps> = ({ type, ...restProps }) => {
  const component = icons[type];
  if (component === undefined) {
    return null;
  }
  return React.createElement<SVGIconProps>(component, restProps);
};

export default Icon;
