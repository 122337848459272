import cls from 'classnames';
import { motion } from 'framer-motion';
import type { Variants } from 'framer-motion';
import styles from './Container.module.scss';
import { CSSProperties } from 'react';

interface ContainerProps {
  id?: string;
  className?: string;
  defaultVariant?: Variants;
  style?: CSSProperties;
}

const variants: Variants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  defaultVariant,
  ...restProps
}) => {
  return (
    <motion.section
      variants={defaultVariant || variants}
      initial="hidden"
      whileInView="visible"
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      className={cls(styles.container, className)}
      {...restProps}
    >
      {children}
    </motion.section>
  );
};

export default Container;
