import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { setVisible as setCookiesNotifyVisible } from '@/stores/cookies-notify';
import styles from './CookiesNotify.module.scss';

const Cookies = () => {
  const { defaultLocale, locale } = useRouter();

  const handleHideCookies = () => {
    setCookiesNotifyVisible(false);
  };

  return (
    <div className={styles.container}>
      <FormattedMessage
        id="cookies.title"
        defaultMessage="By using this website you automatically agree to our <link>cookie policy</link>."
        values={{
          link: (chunks) => (
            <Link
              href={`/a/private-policy?hl=${locale}`}
              locale={defaultLocale}
              target="_blank"
            >
              <a>{chunks}</a>
            </Link>
          ),
        }}
      />
      <div className={styles.confirm} onClick={handleHideCookies}>
        <FormattedMessage id="cookies.confirm" defaultMessage="Go it!" />
      </div>
    </div>
  );
};

export default Cookies;
