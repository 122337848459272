export const Youtube: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#232426" />
      <g clipPath="url(#clip0_52_36305)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8104 7.46334C19.0463 7.70075 19.2157 7.99596 19.3016 8.31941C19.6195 9.51327 19.6195 12.0029 19.6195 12.0029C19.6195 12.0029 19.6195 14.4926 19.3016 15.6864C19.2157 16.0099 19.0463 16.3051 18.8104 16.5425C18.5745 16.7799 18.2803 16.9512 17.9574 17.0393C16.7688 17.3589 12.0175 17.3589 12.0175 17.3589C12.0175 17.3589 7.26628 17.3589 6.0776 17.0393C5.7547 16.9512 5.46059 16.7799 5.22469 16.5425C4.98879 16.3051 4.81939 16.0099 4.73343 15.6864C4.41553 14.4926 4.41553 12.0029 4.41553 12.0029C4.41553 12.0029 4.41553 9.51327 4.73343 8.31941C4.81939 7.99596 4.98879 7.70075 5.22469 7.46334C5.46059 7.22592 5.7547 7.05463 6.0776 6.9666C7.26628 6.64697 12.0175 6.64697 12.0175 6.64697C12.0175 6.64697 16.7688 6.64697 17.9574 6.9666C18.2803 7.05463 18.5745 7.22592 18.8104 7.46334ZM14.4372 12.0034L10.4634 9.74268V14.2641L14.4372 12.0034Z"
          fill="#232426"
        />
      </g>
      <defs>
        <clipPath id="clip0_52_36305">
          <rect
            width="15.204"
            height="15.204"
            fill="white"
            transform="translate(4.40088 4.40088)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const YoutubeActive: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FF0302" />
      <g clipPath="url(#clip0_52_35298)">
        <path
          d="M19.3019 8.31941C19.2159 7.99596 19.0465 7.70075 18.8106 7.46334C18.5747 7.22592 18.2806 7.05463 17.9577 6.9666C16.769 6.64697 12.0178 6.64697 12.0178 6.64697C12.0178 6.64697 7.26652 6.64697 6.07784 6.9666C5.75495 7.05463 5.46083 7.22592 5.22493 7.46334C4.98904 7.70075 4.81963 7.99596 4.73367 8.31941C4.41577 9.51327 4.41577 12.0029 4.41577 12.0029C4.41577 12.0029 4.41577 14.4926 4.73367 15.6864C4.81963 16.0099 4.98904 16.3051 5.22493 16.5425C5.46083 16.7799 5.75495 16.9512 6.07784 17.0393C7.26652 17.3589 12.0178 17.3589 12.0178 17.3589C12.0178 17.3589 16.769 17.3589 17.9577 17.0393C18.2806 16.9512 18.5747 16.7799 18.8106 16.5425C19.0465 16.3051 19.2159 16.0099 19.3019 15.6864C19.6198 14.4926 19.6198 12.0029 19.6198 12.0029C19.6198 12.0029 19.6198 9.51327 19.3019 8.31941Z"
          fill="white"
        />
        <path
          d="M10.4631 14.2636V9.74219L14.4369 12.0029L10.4631 14.2636Z"
          fill="#FF0302"
        />
      </g>
      <defs>
        <clipPath id="clip0_52_35298">
          <rect
            width="15.204"
            height="15.204"
            fill="white"
            transform="translate(4.40088 4.40088)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
