import React from 'react';
import cls from 'classnames';
import LazyImg from './LazyImg';
import styles from './Intl.module.scss';

export const formatList = (...chunks: [parts: React.ReactNode[]]) => (
  <li className={cls(styles.list)}>{chunks}</li>
);

export const formatHighlight = (...chunks: [parts: React.ReactNode[]]) => (
  <span className={cls(styles.highlight, 'format-hlt')}>{chunks}</span>
);

export const formatQuote = (...chunks: [parts: React.ReactNode[]]) => (
  <span className={cls(styles.quote, 'format-quote')}>{chunks}</span>
);

export const formatNewLine = () => (
  <span className={cls(styles.newline, 'format-newline')} />
);

export const formatImg = (chunks) => (
  <LazyImg data-src={chunks} alt="" className={styles.img} />
);

export const formatP = (chunks) => <p className={styles.p}>{chunks}</p>;

export const formatTitle = (chunks) => <p className={styles.title}>{chunks}</p>;

export const formatStrong = (chunks) => (
  <span className={styles.strong}>{chunks}</span>
);

export const formatImgTitle = (chunks) => (
  <p className={styles['img-title']}>{chunks}</p>
);

export const formatLi = (chunks) => <p className={styles.li}>{chunks}</p>;

export const formatCenter = (chunks) => (
  <div className={styles.center}>{chunks}</div>
);
