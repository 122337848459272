export const Twitter: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#232426" />
      <g clipPath="url(#clip0_52_36311)">
        <path
          d="M9.38145 18.3789C15.1188 18.3789 18.2569 13.6256 18.2569 9.50346C18.2569 9.36844 18.2569 9.23404 18.2478 9.10025C18.8583 8.65867 19.3853 8.11191 19.8041 7.48558C19.2348 7.73797 18.6309 7.90341 18.0125 7.97637C18.6637 7.58657 19.1511 6.9734 19.3839 6.25102C18.7716 6.6144 18.1017 6.87047 17.4031 7.00818C16.9328 6.50808 16.3107 6.17692 15.6333 6.06596C14.9558 5.955 14.2606 6.07041 13.6553 6.39435C13.05 6.71828 12.5684 7.23267 12.2849 7.85792C12.0014 8.48317 11.9319 9.18442 12.0872 9.85315C10.847 9.79094 9.63373 9.46863 8.52618 8.90716C7.41864 8.34568 6.44153 7.55759 5.6583 6.59402C5.2594 7.28073 5.13722 8.09366 5.31665 8.86728C5.49607 9.64091 5.96361 10.3171 6.62405 10.7581C6.12761 10.7435 5.64198 10.6096 5.20826 10.3677V10.4072C5.20845 11.1274 5.45776 11.8253 5.91389 12.3827C6.37003 12.94 7.0049 13.3224 7.71084 13.465C7.2516 13.5903 6.76976 13.6086 6.30234 13.5185C6.50175 14.1383 6.88984 14.6803 7.41236 15.0687C7.93487 15.4571 8.5657 15.6725 9.21664 15.6848C8.56989 16.1932 7.82925 16.569 7.03709 16.7909C6.24494 17.0127 5.41681 17.0762 4.6001 16.9777C6.02663 17.8932 7.68646 18.3787 9.38145 18.3765"
          fill="#232426"
        />
      </g>
      <defs>
        <clipPath id="clip0_52_36311">
          <rect
            width="15.204"
            height="15.204"
            fill="white"
            transform="translate(4.6001 4.6001)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TwitterActive: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#1DA1F2" />
      <g clipPath="url(#clip0_52_35299)">
        <path
          d="M9.38145 18.3789C15.1188 18.3789 18.2569 13.6256 18.2569 9.50346C18.2569 9.36844 18.2569 9.23404 18.2478 9.10025C18.8583 8.65867 19.3853 8.11191 19.8041 7.48558C19.2348 7.73797 18.6309 7.90341 18.0125 7.97637C18.6637 7.58657 19.1511 6.9734 19.3839 6.25102C18.7716 6.6144 18.1017 6.87047 17.4031 7.00818C16.9328 6.50808 16.3107 6.17692 15.6333 6.06596C14.9558 5.955 14.2606 6.07041 13.6553 6.39435C13.05 6.71828 12.5684 7.23267 12.2849 7.85792C12.0014 8.48317 11.9319 9.18442 12.0872 9.85315C10.847 9.79094 9.63373 9.46863 8.52618 8.90716C7.41864 8.34568 6.44153 7.55759 5.6583 6.59402C5.2594 7.28073 5.13722 8.09366 5.31665 8.86728C5.49607 9.64091 5.96361 10.3171 6.62405 10.7581C6.12761 10.7435 5.64198 10.6096 5.20826 10.3677V10.4072C5.20845 11.1274 5.45776 11.8253 5.91389 12.3827C6.37003 12.94 7.0049 13.3224 7.71084 13.465C7.2516 13.5903 6.76976 13.6086 6.30234 13.5185C6.50175 14.1383 6.88984 14.6803 7.41236 15.0687C7.93487 15.4571 8.5657 15.6725 9.21664 15.6848C8.56989 16.1932 7.82925 16.569 7.03709 16.7909C6.24494 17.0127 5.41681 17.0762 4.6001 16.9777C6.02663 17.8932 7.68646 18.3787 9.38145 18.3765"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_52_35299">
          <rect
            width="15.204"
            height="15.204"
            fill="white"
            transform="translate(4.6001 4.6001)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
