import type { SVGIconProps } from '.';

const SuccessIcon: React.FC<SVGIconProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="#56CC1D" />
      <path
        d="M23 40.2097L34.6667 52.6674L58 27.752"
        stroke="#FDFDFD"
        strokeWidth="7"
        strokeMiterlimit="16"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default SuccessIcon;
