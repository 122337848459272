import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { useEffect, useState, useRef } from 'react';
import cls from 'classnames';
import LangSelect from '@/components/common/LangSelect';
import Logo from '@/components/common/Logo';
import styles from './Header.module.scss';
import { LOCALE_KEY } from '@/constants/common';
import { gaEventName } from '@/constants/googleAnalytics';
import { addGtagEvent } from '@/utils/addGtagEvent';

export const bannerList = [
  {
    title: 'header.banner.services',
    route: '/services',
    defaultMessage: 'Services',
  },
  {
    title: 'header.banner.news',
    route: '/news',
    defaultMessage: 'News',
  },
  {
    title: 'header.banner.about',
    route: '/about',
    defaultMessage: 'About',
  },
  {
    title: 'header.banner.contact',
    route: '/contact',
    defaultMessage: 'contact',
  },
];

const Header: React.FC = () => {
  const router = useRouter();
  const [home, setHome] = useState<boolean>(true);
  const header = useRef<HTMLHeadElement | null>(null);
  //监听滚动，更改样式
  const handleScroll = () => {
    const scrollTop =
      document.documentElement.scrollTop ||
      window.pageYOffset ||
      document.body.scrollTop;
    setHome(false);

    if (scrollTop !== 0) {
      if (header.current) {
        header.current.style.backgroundColor = '#fff';
        header.current.style.boxShadow = '0px 0px 30px rgba(0, 0, 0, 0.06)';
      }
    } else {
      if (router.pathname === '/') {
        setHome(true);
      }
      if (header.current) {
        header.current.style.backgroundColor = 'transparent';
        header.current.style.boxShadow = 'none';
      }
    }
    if (router.pathname === '/services') {
      header.current.style.boxShadow = 'none';
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setTimeout(handleScroll, 0);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [router]);

  const handleGo2Login = () => {
    addGtagEvent(gaEventName.LOGIN_CLICK);

    localStorage.setItem(LOCALE_KEY, router.locale);
    router.push({ pathname: '/login' }, undefined, {
      locale: router.defaultLocale,
    });
  };

  return (
    <header
      className={cls(styles.header, { [styles['home-style']]: home })}
      ref={header}
    >
      <div className={styles.container}>
        <Link href="/">
          <a className={styles.logo}>
            <Logo home={home} />
          </a>
        </Link>
        <div className={styles.banner}>
          <ul>
            {bannerList.map((item, index) => {
              return (
                <li
                  key={index}
                  className={cls(styles.banner__item, {
                    [styles.banner__item__home]: home,
                  })}
                >
                  <Link href={item.route}>
                    <a
                      className={cls({
                        [styles.active]:
                          router.pathname !== '/' &&
                          router.pathname.includes(item.route),
                      })}
                    >
                      <FormattedMessage
                        id={item.title}
                        defaultMessage={item.defaultMessage}
                      />
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
          <span className={styles.locales}>
            <LangSelect home={home} />
          </span>
          <button className={styles.login}>
            <div onClick={handleGo2Login}>
              <FormattedMessage id="headers.login" defaultMessage="login" />
            </div>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
