export const Wechat: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="black" />
      <path
        d="M14.6727 9.69836C14.8446 9.69836 15.0164 9.71423 15.1874 9.72923C14.7275 7.59475 12.4201 6 9.79179 6C6.85266 6.01031 4.44434 7.99677 4.44434 10.5192C4.44434 11.9733 5.24713 13.1727 6.58066 14.1013L6.0424 15.6942L7.91421 14.7666C8.5848 14.8978 9.12312 15.0289 9.78518 15.0289C9.95705 15.0289 10.1205 15.0214 10.2838 15.0064C10.1777 14.6442 10.1227 14.2689 10.1205 13.8914C10.1281 11.5789 12.1321 9.6993 14.6736 9.6993L14.6727 9.69836H14.6727ZM11.7959 8.26098C12.202 8.26098 12.4664 8.52333 12.4664 8.92622C12.4664 9.31978 12.202 9.59152 11.7959 9.59152C11.3983 9.59152 10.9931 9.32823 10.9931 8.9262C10.9931 8.52333 11.3983 8.26098 11.7959 8.26098ZM8.05304 9.59152C7.65641 9.59152 7.25032 9.32823 7.25032 8.9262C7.25032 8.52333 7.65641 8.26098 8.05304 8.26098C8.4507 8.26098 8.72363 8.52333 8.72363 8.92622C8.72363 9.31978 8.4592 9.59152 8.05304 9.59152ZM19.5555 13.8361C19.5555 11.7166 17.4191 9.98414 15.0089 9.98414C12.4674 9.98414 10.4633 11.7166 10.4633 13.8361C10.4633 15.9631 12.4674 17.689 15.0089 17.689C15.5397 17.689 16.078 17.5579 16.6154 17.4258L18.0811 18.2222L17.676 16.8991C18.7527 16.0877 19.5555 15.027 19.5555 13.8361ZM13.5355 13.1708C13.2711 13.1708 12.9972 12.9085 12.9972 12.6368C12.9972 12.3744 13.2711 12.1027 13.5355 12.1027C13.9416 12.1027 14.2061 12.366 14.2061 12.6368C14.2061 12.9085 13.9416 13.1708 13.5355 13.1708V13.1708ZM16.4756 13.1708C16.2102 13.1708 15.9448 12.9085 15.9448 12.6368C15.9448 12.3744 16.2093 12.1027 16.4756 12.1027C16.8808 12.1027 17.1461 12.366 17.1461 12.6368C17.1461 12.9085 16.8808 13.1708 16.4756 13.1708V13.1708Z"
        fill="#232426"
      />
    </svg>
  );
};

export const WechatActive: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#05CE65" />
      <path
        d="M14.6727 9.69836C14.8446 9.69836 15.0164 9.71423 15.1874 9.72923C14.7275 7.59475 12.4201 6 9.79179 6C6.85266 6.01031 4.44434 7.99677 4.44434 10.5192C4.44434 11.9733 5.24713 13.1727 6.58066 14.1013L6.0424 15.6942L7.91421 14.7666C8.5848 14.8978 9.12312 15.0289 9.78518 15.0289C9.95705 15.0289 10.1205 15.0214 10.2838 15.0064C10.1777 14.6442 10.1227 14.2689 10.1205 13.8914C10.1281 11.5789 12.1321 9.6993 14.6736 9.6993L14.6727 9.69836H14.6727ZM11.7959 8.26098C12.202 8.26098 12.4664 8.52333 12.4664 8.92622C12.4664 9.31978 12.202 9.59152 11.7959 9.59152C11.3983 9.59152 10.9931 9.32823 10.9931 8.9262C10.9931 8.52333 11.3983 8.26098 11.7959 8.26098ZM8.05304 9.59152C7.65641 9.59152 7.25032 9.32823 7.25032 8.9262C7.25032 8.52333 7.65641 8.26098 8.05304 8.26098C8.4507 8.26098 8.72363 8.52333 8.72363 8.92622C8.72363 9.31978 8.4592 9.59152 8.05304 9.59152ZM19.5555 13.8361C19.5555 11.7166 17.4191 9.98414 15.0089 9.98414C12.4674 9.98414 10.4633 11.7166 10.4633 13.8361C10.4633 15.9631 12.4674 17.689 15.0089 17.689C15.5397 17.689 16.078 17.5579 16.6154 17.4258L18.0811 18.2222L17.676 16.8991C18.7527 16.0877 19.5555 15.027 19.5555 13.8361ZM13.5355 13.1708C13.2711 13.1708 12.9972 12.9085 12.9972 12.6368C12.9972 12.3744 13.2711 12.1027 13.5355 12.1027C13.9416 12.1027 14.2061 12.366 14.2061 12.6368C14.2061 12.9085 13.9416 13.1708 13.5355 13.1708V13.1708ZM16.4756 13.1708C16.2102 13.1708 15.9448 12.9085 15.9448 12.6368C15.9448 12.3744 16.2093 12.1027 16.4756 12.1027C16.8808 12.1027 17.1461 12.366 17.1461 12.6368C17.1461 12.9085 16.8808 13.1708 16.4756 13.1708V13.1708Z"
        fill="white"
      />
    </svg>
  );
};
