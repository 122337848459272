import { Fragment, useEffect } from 'react';
import NextHeader from 'next/head';
import lozad from 'lozad';
import useCookiesNotify from '@/stores/cookies-notify';
import CookiesNotify from '@/components/common/CookiesNotify';
import { STATIC_ASSETS_URL } from '@/constants/common';
import Header from './Header';
import Footer from './Footer';

const MainLayout: React.FC = ({ children }) => {
  const cookiesNotifyVisible = useCookiesNotify((state) => state.visible);

  useEffect(() => {
    //懒加载背景标题
    const observer = lozad('.lazy-img-show', {
      load: function (div: HTMLDivElement) {
        div.classList.add('lazy-img');
        div.classList.remove('lazy-img-show');
      },
    });
    observer.observe();
  });

  return (
    <Fragment>
      <NextHeader>
        <link
          href={`${STATIC_ASSETS_URL}/overseacdn/homepage/static/fonts/zh/FZLanTingHeiS-B-GB.css`}
          rel="stylesheet"
        />
        <link
          href={`${STATIC_ASSETS_URL}/overseacdn/homepage/static/fonts/en/Gilroy.css`}
          rel="stylesheet"
        />
        <link
          href={`${STATIC_ASSETS_URL}/overseacdn/homepage/static/fonts/italic/HarmonyOS_Sans_Italic.css`}
          rel="stylesheet"
        />
      </NextHeader>
      <Header />
      {children}
      <Footer />
      {cookiesNotifyVisible && <CookiesNotify />}
    </Fragment>
  );
};

export default MainLayout;
