import cls from 'classnames';
import style from '@/layouts/main/Header.module.scss';
import { useRouter } from 'next/router';
import { useState } from 'react';

interface LangSelectProps {
  home: boolean;
}

const LangSelect: React.FC<LangSelectProps> = ({ home }) => {
  const router = useRouter();
  const { pathname } = router;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showMenuTimer, setShowMenuTimer] = useState<number>(undefined);

  const handleChangeLang = (lang: string) => {
    setShowMenu(false);
    if (router.locale === lang) {
      return;
    }

    if (pathname === '/news/[slug]') {
      router.replace(router.asPath, router.asPath, {
        locale: lang || 'en-US',
      });
    } else {
      router.push(router.asPath, router.asPath, { locale: lang || 'en-US' });
    }

    if (document !== undefined) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    }
  };

  const handleMouseOver = () => {
    window.clearTimeout(showMenuTimer);
    setShowMenuTimer(undefined);
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    const timer = window.setTimeout(() => {
      setShowMenu(false);
    }, 250);

    setShowMenuTimer(timer);
  };

  return (
    <div
      className={cls(style['menu-item'])}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <span className={cls({ zh: router.locale === 'zh-CN' })}>
        {router.locale === 'zh-CN' ? '简体中文 ' : 'English '}
      </span>
      {home ? (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0935 6.125L6.99991 9.21859L3.90632 6.125"
            stroke="white"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0935 6.125L6.99991 9.21859L3.90632 6.125"
            stroke="#232426"
            strokeLinecap="square"
          />
        </svg>
      )}
      {showMenu && (
        <div
          style={{ width: 128 }}
          className={cls(
            style['menu-item-select-panel'],
            'absolute fade-in-bottom'
          )}
        >
          <span
            className={cls(style['menu-item-select-item'])}
            onClick={handleChangeLang.bind(null, 'en-US')}
          >
            English
          </span>
          <span
            className={cls(style['menu-item-select-item'], {
              zh: router.locale === 'zh-CN',
            })}
            onClick={handleChangeLang.bind(null, 'zh-CN')}
          >
            简体中文
          </span>
        </div>
      )}
    </div>
  );
};

export default LangSelect;
