export const Linkedin: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#232426" />
      <path
        d="M5.295 9.105H8.19V18.7275H5.295V9.105ZM6.75 5.25C6.35218 5.25 5.97064 5.40804 5.68934 5.68934C5.40804 5.97064 5.25 6.35218 5.25 6.75C5.27112 7.13335 5.43843 7.49402 5.71747 7.75772C5.99652 8.02142 6.36607 8.16808 6.75 8.1675C7.13394 8.16808 7.50348 8.02142 7.78253 7.75772C8.06158 7.49402 8.22888 7.13335 8.25 6.75C8.25 6.35218 8.09197 5.97064 7.81066 5.68934C7.52936 5.40804 7.14783 5.25 6.75 5.25ZM17.8125 10.38C17.5191 10.0416 17.1506 9.77658 16.7365 9.60599C16.3224 9.4354 15.8741 9.364 15.4275 9.3975C14.8503 9.38408 14.2802 9.52771 13.7783 9.81303C13.2763 10.0984 12.8613 10.5147 12.5775 11.0175H12.5325V9.105H10.1025C10.1475 10.0875 10.1025 18.7275 10.1025 18.7275H12.9975V12.75C13.0639 12.4213 13.2471 12.1278 13.5132 11.9237C13.7793 11.7196 14.1103 11.6189 14.445 11.64C15.3825 11.64 15.8475 12.3225 15.8475 13.6425V18.75H18.75V13.275C18.8082 12.227 18.4739 11.1949 17.8125 10.38Z"
        fill="#232426"
      />
    </svg>
  );
};

export const LinkedinActive: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#3165BB" />
      <path
        d="M5.295 9.105H8.19V18.7275H5.295V9.105ZM6.75 5.25C6.35218 5.25 5.97064 5.40804 5.68934 5.68934C5.40804 5.97064 5.25 6.35218 5.25 6.75C5.27112 7.13335 5.43843 7.49402 5.71747 7.75772C5.99652 8.02142 6.36607 8.16808 6.75 8.1675C7.13394 8.16808 7.50348 8.02142 7.78253 7.75772C8.06158 7.49402 8.22888 7.13335 8.25 6.75C8.25 6.35218 8.09197 5.97064 7.81066 5.68934C7.52936 5.40804 7.14783 5.25 6.75 5.25ZM17.8125 10.38C17.5191 10.0416 17.1506 9.77658 16.7365 9.60599C16.3224 9.4354 15.8741 9.364 15.4275 9.3975C14.8503 9.38408 14.2802 9.52771 13.7783 9.81303C13.2763 10.0984 12.8613 10.5147 12.5775 11.0175H12.5325V9.105H10.1025C10.1475 10.0875 10.1025 18.7275 10.1025 18.7275H12.9975V12.75C13.0639 12.4213 13.2471 12.1278 13.5132 11.9237C13.7793 11.7196 14.1103 11.6189 14.445 11.64C15.3825 11.64 15.8475 12.3225 15.8475 13.6425V18.75H18.75V13.275C18.8082 12.227 18.4739 11.1949 17.8125 10.38Z"
        fill="white"
      />
    </svg>
  );
};
