import { FormattedMessage } from 'react-intl';
import { formatNewLine, formatImg } from '@/components/common/Intl';
import Link from 'next/link';
import Container from '@/components/common/Container';
import type { Variants } from 'framer-motion';
import styles from './Business.module.scss';
import { useRouter } from 'next/router';
import cls from 'classnames';
import { gaEventName } from '@/constants/googleAnalytics';
import { addGtagEvent } from '@/utils/addGtagEvent';

const businessVariant: Variants = {
  hidden: { opacity: 0, y: 220 },
  visible: {
    opacity: 1,
    y: 100,
  },
};

const Business: React.FC = () => {
  const router = useRouter();

  const isHome = router.pathname === '/';
  const isServices = router.pathname === '/services';
  return (
    <section
      className={cls(styles.business, {
        [styles['business-others']]: !isHome,
        [styles['business-services']]: isServices,
      })}
    >
      <Container defaultVariant={businessVariant}>
        <div className={cls(styles.container, 'lazy-img-show')}>
          <div className={cls(styles.title, 'font')}>
            <FormattedMessage
              id="footer.business.title"
              defaultMessage="Fusing technology and brand force to<n><n/>drive business growth with youdao Ads"
              values={{
                n: formatNewLine,
                myImg: formatImg,
              }}
            />
          </div>
          <button
            className={styles.explore}
            onClick={() => {
              addGtagEvent(gaEventName.EXPLORE_BUSINESS_CLICK);
            }}
          >
            <Link href="/contact">
              <div>
                <FormattedMessage
                  id="footer.business.explore"
                  defaultMessage="Explore Now"
                />
              </div>
            </Link>
          </button>
        </div>
      </Container>
    </section>
  );
};

export default Business;
